/* eslint react/prop-types: 0 */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
// import { useEffect, useState } from "react";
import BackgroundImage from 'gatsby-background-image'
import { Parallax } from 'react-scroll-parallax';
// import Img from 'gatsby-image';

const ParallaxImage = ({ children, imagename = '', posY = [-40, 0], posX = [0, 0], className = '' }) => {

    const data = useStaticQuery(graphql`
        query {
            allImageSharp {
                edges {
                    node {
                        fluid {
                            aspectRatio
                            originalName
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    `)

    const imagedata = data.allImageSharp.edges.filter(
        (x) => { return x.node.fluid.originalName === imagename }
    )[0].node.fluid;

    return (
        <Parallax className={className} y={posY} x={posX} tagOuter="figure">
            <BackgroundImage
                fluid={imagedata}
                imgStyle={{objectFit: 'cover'}}
                style={{
                    height: `100%`, maxHeight: '100%'
                }}
            />
            {children}
        </Parallax>
    )
};

export default ParallaxImage
