/* eslint react/prop-types: 0 */
import React from 'react'
import ParallaxImage from './parallax'
import Link from 'gatsby-link';

const Hero = (
    {
        image_name,
        buttonPrimary,
        buttonPrimaryUrl,
        buttonSecondary,
        buttonSecondaryUrl,
        buttonSeparator,
        lead,
        title
    }
    ) => {

    return(
        <section className="hero">
            <ParallaxImage
               className="parallax"
                imagename={image_name}
            />
            <div className="heroInner">
                <div className="hero-calltoAction">
                    <div className="hero-calltoAction_content">
                        <h1 className="hero-calltoAction_content-title" dangerouslySetInnerHTML={{__html: title}} />
                        <h2 className="hero-calltoAction_content-subtitle">{lead}</h2>
                        <div data-sal="slide-up" data-sal-delay="300">
                            <Link
                                className="hero-calltoAction_content-btn"
                                to={buttonPrimaryUrl}>
                                {buttonPrimary}
                            </Link>
                            {buttonSecondary && (
                                <>
                                    <span className="d-none d-sm-inline">&nbsp;&nbsp;{buttonSeparator}&nbsp;&nbsp;</span>
                                    <Link
                                        className="hero-calltoAction_content-btn--secondary"
                                        to={buttonSecondaryUrl}>
                                        {buttonSecondary}
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero