/* eslint react/prop-types: 0 */
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image';

const InlineImage = ({ image_name }) => {

    const data = useStaticQuery(graphql`
        query {
            allImageSharp {
                edges {
                    node {
                        fluid {
                            originalName
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    `)

    const imagedata = data.allImageSharp.edges.filter(
        (x) => { return x.node.fluid.originalName === image_name }
    )[0].node.fluid;

    return (
        <Img
            fluid={imagedata}
        />
    )
};

export default InlineImage
