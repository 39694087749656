import React from 'react';
import {useIntl} from 'gatsby-plugin-react-intl'
import {SEO} from '../components/seo';
import {Layout} from '../components/layout';
import Hero from '../components/hero';
import Services from '../components/services';
import Contact from '../components/contact';
import CalltoAction from '../components/call-to-action';
import ParallaxImage from "../components/parallax";
import useSiteMetadata from "../helpers/hooks/use-site-metadata";
// import {ParallaxCache} from "../helpers/hooks/parallaxCache";
// import { withController } from "react-scroll-parallax"

const IndexPage = () => {
  const intl = useIntl().formatMessage;

  return (
    <Layout fullwidth>
        {/*<ParallaxCache />*/}
        <SEO title="Home"/>
        <Hero
            // title={intl({ id: 'welcome.title' })}
            title="Experience <br/>& Knowledge"
            lead={intl({ id: 'welcome.lead' })}
            buttonPrimary={intl({ id: 'welcome.buttonPrimary.label' })}
            buttonPrimaryUrl={intl({ id: 'welcome.buttonPrimary.url' })}
            buttonSecondary={intl({ id: 'welcome.buttonSecondary.label' })}
            buttonSecondaryUrl={intl({ id: 'welcome.buttonSecondary.url' })}
            image_name="hero.jpg"
        />
        <Services style={{overflow: "hidden", position: "relative", height: "auto"}}

            id={intl({ id: 'nav.services.url' })}
            title={intl({ id: 'services.title' })}
            lead={intl({ id: 'services.lead' })}
            image_name={"consultancy.jpg"}
        />
        <section
            style={{
                overflow: "hidden", position: "relative", height: "35em"}}>
            <ParallaxImage
                posY={[-30, 0]}
                posX={[0, 0]}
                className=""
                imagename={"quote.jpg"}
            />
            <div
                className="position-absolute d-flex align-items-center justify-content-center text-white"
                style={{overflow: "hidden", top: "0", left: "0", bottom: "0", right: "0"}}>
                <blockquote className="text-center h2 -white">
                    To succeed… You need to find something to hold on
                    to, something to motivate you, something to inspire you.<br/><br/>
                    <span className="font-weight-bold small text-right">– Tony Dorsett</span>
                </blockquote>
            </div>

        </section>
        <CalltoAction
            className={`img-left bg-white`}
            title={intl({ id: 'callToAction.title' })}
            lead={intl({ id: 'callToAction.lead' })}
            buttonPrimary={intl({ id: 'callToAction.buttonPrimary.label' })}
            buttonPrimaryUrl={intl({ id: 'callToAction.buttonPrimary.url' })}
            image_name={"consultancy.jpg"}
        />
        <CalltoAction
            className={`bg-dark`}
            title={intl({ id: 'callToAction2.title' })}
            lead={intl({ id: 'callToAction2.lead' })}
            buttonPrimary={intl({ id: 'callToAction2.buttonPrimary.label' })}
            buttonPrimaryUrl={intl({ id: 'callToAction2.buttonPrimary.url' })}
            image_name={"develop.jpeg"}
        />
        <Contact
            image_name={"guust-de-jong.png"}
            contactName={intl({ id: 'contact.person.name' })}
            contactPosition={intl({ id: 'contact.person.position' })}
            contactText={intl({ id: 'contact.person.specialism' })}
            title={intl({ id: 'contact.title' })}
            siteMetadata={useSiteMetadata()}
            // titleForm={intl({ id: 'contact.form.title' })}
            leadForm={intl({ id: 'contact.form.lead' })}
            name={intl({ id: 'contact.form.name' })}
            email={intl({ id: 'contact.form.email' })}
            message={intl({ id: 'contact.form.message' })}
            button={intl({ id: 'contact.form.button' })}
            successMessage={intl({ id: 'contact.form.successMessage' })}
            id={intl({ id: 'nav.contact.url' })}
        />
    </Layout>
  );
};
// export default withController(IndexPage)

export default IndexPage;
