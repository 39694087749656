import React from 'react'
import PropTypes from 'prop-types'
// import { useIntl } from 'gatsby-plugin-react-intl'
// import InlineImage from './inline-image';
// import ParallaxImage from "./parallax";
// import { IconContext } from "react-icons";
// import {GiEarthAsiaOceania} from "react-icons/gi";
// import {FaGlobeEurope} from "react-icons/fa";

const Services = (
    { id,
      // image_name
    }
    ) => {
  // const intl = useIntl().formatMessage;
  return (
    <section className="services position-relative" id={id}>
        <div className="container">
            {/*<h2 className="text-muted text-center">*/}
            {/*    /!*{intl({ id: 'services.title' })}*!/*/}
            {/*    From Consultancy, Agency to Trade; expand horizons.*/}
            {/*</h2>*/}
          <div className="grid services-info">
              <div className="services-info__content text-center"  data-sal-delay="300" data-sal="fade">
                  <p className='font-weight-normal h3 mb-0'>
                      We help American & Asian companies with <strong>business in Europe</strong>.
                      {/*{intl({ id: 'services.lead' })}*/}
                  </p>
              </div>
              <div className="services-info__content text-center"  data-sal-delay="300" data-sal="fade">
                  <p className='font-weight-normal h3 mb-0'>
                      We help American & European companies with <strong>business in Asia</strong>.
                      {/*{intl({ id: 'services.lead' })}*/}
                  </p>
              </div>
          </div>
      </div>
    </section>
  )
}

export default Services

Services.propTypes = {
  id: PropTypes.string.isRequired,
  image_name: PropTypes.string.isRequired
}
