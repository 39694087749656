/* eslint react/prop-types: 0 */
import React from 'react'
import {Link} from 'gatsby';
import ParallaxImage from './parallax'
import PropTypes from 'prop-types';

const CalltoAction = ({
         className = '',
         title,
         lead,
         buttonPrimary,
         buttonPrimaryUrl,
         image_name
     }) => {

    const posTextX = className === 'img-left' ? [0, -20] : [-20, 0] ;

    return (
        <section className={`callToAction ${className} `}>
            <div className="container">
                <div className="row">
                    <div className="callToAction__content">
                        <div className="callToAction-textInner"
                             data-sal="slide-up" data-sal-delay="300">
                            <h3 className="callToAction__content-title" dangerouslySetInnerHTML={{__html: title}} />
                            <p className="callToAction__content-text"  dangerouslySetInnerHTML={{__html: lead}} />
                            <Link
                                className="callToAction__content-btn"
                                to={buttonPrimaryUrl}>
                                {buttonPrimary}
                            </Link>
                        </div>
                    </div>
                    <div className="callToAction__image d-none d-sm-block" data-sal="slide-up" data-sal-delay="300">
                            <ParallaxImage
                                posY={[0, 0]}
                                posX={posTextX}
                                className=""
                                imagename={image_name}
                            />
                    </div>
                </div>
            </div>
        </section>
    )
};

export default CalltoAction;


CalltoAction.propTypes = {
    title: PropTypes.string.isRequired,
    lead: PropTypes.string.isRequired,
    buttonPrimary: PropTypes.string.isRequired,
    buttonPrimaryUrl: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
};

