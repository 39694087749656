import React from 'react'
import PropTypes from 'prop-types'
import { BiEnvelope, BiPhone } from 'react-icons/bi';

import ContactForm from './contact-form'
import InlineImage from "./inline-image";

const Contact = ({
  image_name,
  contactName,
  contactPosition,
  contactText,
  button,
  email,
  id,
  message,
  name,
  siteMetadata,
  successMessage,
  title,
  // titleForm,
  leadForm
}) => (
  <section className="contact container" id={id}>
    <div className="d-flex flex-column">
      <div className="col-12 col-sm-10">
        <div className="row">
          <h2 className="col-12 text-uppercase text-center" data-sal="fade">{title}</h2>
        </div>
        <div className="row mb-6">
          <div className="col-sm-3 col-6 mx-auto mb-4 mb-sm-0">
            <InlineImage
                image_name={image_name}
            />
          </div>
          <div className="col-sm-9 col-12">
            <span className="font-weight-bold d-block">{contactName}</span>
            <span className="font-italic mb-3 d-block">{contactPosition}</span>
            <span className="font-weight-normal text-dark d-block mb-3">{contactText}</span>
            <div className="d-flex justify-content-start mb-2">
              <span className="mr-2"><BiEnvelope/></span>
              <a href={`mailto:${siteMetadata.email}`} className="text-black">
                {siteMetadata.email}
              </a>
            </div>
            <div className="d-flex justify-content-start">
              <span className="mr-2"><BiPhone/></span>
              <span className="text-default">{siteMetadata.phone}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <ContactForm
                button={button}
                email={email}
                message={message}
                name={name}
                successMessage={successMessage}
                // titleForm={titleForm}
                leadForm={leadForm}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Contact

Contact.propTypes = {
  image_name: PropTypes.string.isRequired,
  contactName: PropTypes.string.isRequired,
  contactPosition: PropTypes.string.isRequired,
  contactText: PropTypes.string.isRequired,
  button: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  siteMetadata: PropTypes.object.isRequired,
  successMessage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // titleForm: PropTypes.string.isRequired,
  leadForm: PropTypes.string.isRequired
}
